export const FirebaseConfig = {
	"projectId": "ton-x-main",
	"appId": "1:13505566134:web:d1f8196ed0ded5fc0edd17",
	"databaseURL": "https://ton-x-main-default-rtdb.firebaseio.com",
	"storageBucket": "ton-x-main.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBDD_atSjhSYI_67ERrRZ6XS_kPi6ouAvM",
	"authDomain": "ton-x-main.firebaseapp.com",
	"messagingSenderId": "13505566134",
	"measurementId": "G-7DC9GQCLMY"
};